import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, LinkBox, Section, Link, Strong, Span, List, Hr, Image, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward, MdBook, MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
import { FaRegWindowClose, FaGlobe, FaRegBuilding, FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import { FiRadio, FiTv, FiMic } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Bemutatkozás
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Sikeres Kommunikáció - Bemutatkozás"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar overflow-x="hidden" overflow-y="hidden" />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/business-team-giving-high-five.jpg?v=2023-12-14T12:54:28.980Z) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			quarkly-title="Hero"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Siker. Célorientáltan.
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Szárnyalni csak másokat emelve lehet
						</Text>
					</Box>
				</Box>
			</Box>
			<LinkBox
				position="relative"
				color="--brandLight"
				margin="96px auto 0px auto"
				max-width="max-content"
				href="#partner-section"
			>
				<Text margin="8px 0" text-transform="uppercase">
					Kik vagyunk
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</LinkBox>
		</Section>
		<Section
			padding="90px 0px 0 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			quarkly-title="About"
			box-sizing="border-box"
			md-padding="50px 0px 50px 0px"
			sm-padding="0px 0 0px 0"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				align-items="center"
				margin="0px 32px 0px 32px"
				sm-padding="24px 0px 24px 0px"
			/>
			<Text
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				lg-text-align="center"
				font="--base"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				text-transform="uppercase"
				text-align="center"
			>
				Rólunk
			</Text>
			<Box min-width="100px" min-height="100px" sm-display="grid" sm-margin="36px 0px 0px 0px">
				<Box display="inline">
					<Link
						href="#services"
						color="#000000"
						padding="15px 15px 15px 15px"
						font="--lead"
						text-decoration="none"
						display="inline"
						hover-color="--brandSecondary"
					>
						Szolgáltatásaink
					</Link>
					<Text margin="0px 0px 0px 0px" display="inline" sm-display="none">
						|
					</Text>
				</Box>
				<Box display="inline">
					<Link
						href="#media"
						color="#000000"
						padding="15px 15px 15px 15px"
						font="--lead"
						text-decoration="none"
						display="inline"
						hover-color="--brandSecondary"
					>
						Médiamegjelenések
					</Link>
					<Text margin="0px 0px 0px 0px" display="inline" sm-display="none">
						|
					</Text>
				</Box>
				<Box display="inline">
					<Link
						href="#contact"
						color="#000000"
						padding="15px 15px 15px 15px"
						font="--lead"
						text-decoration="none"
						display="inline"
						hover-color="--brandSecondary"
					>
						Kapcsolat
					</Link>
				</Box>
			</Box>
		</Section>
		<Section background="#ffffff" padding="60px 0 80px 0" quarkly-title="Partnerek" id="partner-section">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 60px 0px" color="--brandPrimary" font="--headline2">
				Partnerek
			</Text>
			<Text
				text-align="left"
				color="--greyD2"
				font="--lead"
				margin="16px 600px 32px 0px"
				lg-margin="16px 0 16px 0px"
				display="none"
			>
				We are united by our passion for innovation and our commitment to delivering high-quality solutions to our clients.{"   "}
			</Text>
			<Components.TeamWrapper
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px 16px"
				display="grid"
				sm-grid-template-columns="repeat(2, 1fr)"
				justify-content="center"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="12px 30px "
				align-items="center"
				justify-items="center"
				align-content="center"
			>
				<Components.TeamMember
					align-items="center"
					align-content="center"
					width="220px"
					showExtra="Show"
					sm-width="auto"
					textContent="Tizenkét éve foglalkozom vezetőképzéssel, amelyet egy közel egy évtizedes sikeres pályafutás előzött meg egy multinacionális iparvállalat vezetőjeként. Személyes tapasztalataim és mélyreható ismereteim révén tisztában vagyok a vezetőképzés gyakorlati és rendszerbeli fontosságával.<newline>A legmodernebb módszerrekkel biztosítom számukra a szükséges eszközöket ahhoz, hogy a legjobb szakmai képességük szerint tudják az elvárt hozzáadott értéket megteremteni."
				>
					<Override slot="text">
						Dr. Breznay Attila
					</Override>
					<Override slot="text1">
						Tréner & Tanácsadó
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/b-attila.webp?v=2025-02-01T14:09:29.265Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
					<Box
						position="absolute"
						display="grid"
						justify-content="center"
						align-items="center"
						align-content="center"
						justify-items="center"
						background="#be7e0f linear-gradient(30deg,--color-gold 0%,rgba(221, 171, 83, 0.8) 48.1%,--color-gold 100%)"
						color="--brandLight"
						padding="4px 8px 3px 8px"
						top="122px"
						border-radius="3px 12px 3px 12px"
						box-shadow="--l"
						opacity="0.9"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 12px/1.5 --fontFamily-googleRubik" text-shadow="1px 1px 1px --color-brandPrimary">
							Kiemelt Partnerünk
						</Text>
					</Box>
				</Components.TeamMember>
				<Components.TeamMember
					align-items="center"
					align-content="center"
					width="220px"
					textContent="Tizenöt éves értékesítői karrierem alatt több száz sikeres üzletet kötöttem, értékesítési csapatokat képeztem ki az ország minden szegletében, és számos nagyvállalat értékesítési folyamatát terveztem meg. Több ezer tárgyalás és számtalan üzleti tanácsadás után első kézből mondhatom, hogy bár az értékesítés kiemelt szerepet tölt be a vállalatok életében, mégsem kapja meg a kellő megbecsülést. Csatlakozz hozzám és változtassuk meg együtt a szakma megítélését úgy, hogy ügyfélközpontú, igaz szakember válik belőled."
					showExtra="Show"
					sm-width="auto"
				>
					<Override slot="text">
						Koch Róbert
					</Override>
					<Override slot="text1">
						Tréner
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/k-robert.webp?v=2025-02-01T14:09:29.280Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
				<Components.TeamMember
					align-items="center"
					align-content="center"
					width="220px"
					textContent="Fiatal koromtól fogva hajt az emberi interakciók és a kommunikáció iránti szenvedély. A brüsszeli NATO központban és az Európai Parlamentben eltöltött idő alatt beleszagoltam a kommunikáció magas szintű alkalmazásába. Jelenleg szervezetfejlesztéssel és projektmenedzsmenttel foglalkozom, és hiszem, hogy a kivételes termékek mögött mindig kivételes csapatok állnak, akiknek erős kultúrájuk és stabil működési modelljük van. Munkám során a pszichológia segítségével kifejezetten abban segítek szervezeteknek, hogy sikeresebbek legyenek azáltal, jobban megértik egymást és az emberi dinamikákat."
					showExtra="Show"
					sm-width="auto"
					md-grid-column="1/3"
				>
					<Override slot="text">
						Farkas László
					</Override>
					<Override slot="text1">
						Szervezetfejlesztő
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/f-laszlo.webp?v=2025-02-01T14:09:29.266Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
			</Components.TeamWrapper>
		</Section>
		<Section background="#ffffff" padding="60px 0 80px 0" quarkly-title="Csapat">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 60px 0px" color="--brandPrimary" font="--headline2">
				Csapat
			</Text>
			<Text
				text-align="left"
				color="--greyD2"
				font="--lead"
				margin="16px 600px 32px 0px"
				lg-margin="16px 0 16px 0px"
				display="none"
			>
				We are united by our passion for innovation and our commitment to delivering high-quality solutions to our clients.{"   "}
			</Text>
			<Components.TeamWrapper
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="24px 16px"
				display="grid"
				sm-grid-template-columns="repeat(2, 1fr)"
				justify-items="center"
				align-items="stretch"
				align-content="center"
				justify-content="center"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="repeat(3, 1fr)"
				sm-grid-gap="10px 24px"
			>
				<Components.TeamMember
					align-items="center"
					align-content="center"
					width="220px"
					showExtra="Show"
					sm-width="auto"
					textContent="Digitális marketing és technikai problémamegoldás területén szerzett tapasztalataimmal segítek a kitűzött célok elérésében. Szakértelmem a stratégiai marketingtervezéstől a komplex technikai kihívások megoldásáig terjed. Munkám alappillére a piaci magatartások, célközönség és a digitális dinamikák mintáinak felismerése és ezekhez való igazodás."
				>
					<Override slot="text">
						Bea Zsolt J.
					</Override>
					<Override slot="text1">
						Systems Manager
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/b-zsolt.webp?v=2025-02-01T14:09:29.272Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
				<Components.TeamMember
					align-items="center"
					align-content="center"
					width="220px"
					showExtra="Show"
					sm-width="auto"
					textContent="Mindig is lenyűgözött az emberek személyisége, reakcióik, és az, hogy hogyan lehet ezeket az információkat elemezni és ezáltal hatékonyabban kommunikálni. Tíz év informatikában eltöltött év után újra felébredt bennem régi szenvedélyem a videóvágás és az alkotás iránt. Amikor az univerzum újra összehozott Bencével, beszélgetésünk során rádöbbentem, hogy itt van a lehetőség arra, hogy két kedvenc tevékenységemet – az emberekkel való hatékony kommunikációt és a kreatív alkotást – ötvözve fejlődhessek és valami újat hozhassak létre."
				>
					<Override slot="text">
						Kovács Péter
					</Override>
					<Override slot="text1">
						Médiadizájner
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/k-peter.webp?v=2025-02-01T14:09:29.268Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
				<Components.TeamMember
					textContent="Az életem során mindig is szenvedélyem volt a pszichológia és a más embereknek való segítés. 2020 óta dolgozom a csapat tagjaként, és minden nappal egyre jobban élvezem a munkát, mert hiszem, hogy értéket teremtünk, és hogy szárnyalni, csak másokat emelve lehet. 2023-ban pszichológia diplomát szereztem, jelenleg pedig jogot tanulok. "
					align-items="center"
					align-content="center"
					width="220px"
					showExtra="Show"
					sm-width="auto"
				>
					<Override slot="text">
						Karászi Patrik
					</Override>
					<Override slot="text1">
						Tartalomfejlesztő
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/k-patrik.webp?v=2025-02-01T14:09:29.267Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
				<Components.TeamMember
					textContent="Azért vagyok itt, hogy tanuljak és fejlődjek, és hogy önmagam legjobb verziója lehessek. Az a fajta világnézet, amire itt rátaláltam egyedi, és valódi értékeket képvisel. Megérteni az emberek cselekedeteinek mögöttes tartalmát, és empátiával fordulni feléjük, olyan készség, ami mindnyájunk hasznára lehet. Szeretném ezt a készséget egyszer majd magaménak tudni, és úgy fordulni embertásaimhoz, hogy az épitő legyen. Változásra van szükség a kapcsolatainkban, és ezt a változást csak úgy érhetjük el ha mindannyian teszünk érte. "
					align-items="center"
					align-content="center"
					width="220px"
					lg-grid-column="1/4"
					md-grid-column="auto"
					showExtra="Show"
					sm-width="auto"
				>
					<Override slot="text">
						Szarvadi Alexandra
					</Override>
					<Override slot="text1">
						Tartalomfejlesztő
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/sz-alexandra.webp?v=2025-02-01T14:09:29.273Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
			</Components.TeamWrapper>
		</Section>
		<Section background="#ffffff" padding="60px 0 80px 0" quarkly-title="Mentorok">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 60px 0px" color="--brandPrimary" font="--headline2">
				Kiemelt Mentorok
			</Text>
			<Text
				text-align="left"
				color="--greyD2"
				font="--lead"
				margin="16px 600px 32px 0px"
				lg-margin="16px 0 16px 0px"
				display="none"
			>
				We are united by our passion for innovation and our commitment to delivering high-quality solutions to our clients.{"   "}
			</Text>
			<Components.TeamWrapper
				grid-template-columns="1fr 1fr"
				grid-gap="16px"
				display="grid"
				sm-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
			>
				<Components.TeamMember
					align-items="center"
					align-content="center"
					width="220px"
					showExtra="Show"
					textContent="Hosszú idővel ezelőtt, egyetlen küldetéssel kezdtem ezen a területen: át akartam alakítani azt, amit az emberi viselkedés befolyásolásáról gondoltak, és túllépni azon korlátokon, amelyeket korábban lehetetlennek tűntek. Kiskorom óta lenyűgöz a viselkedéstudomány, amely mindig újabb és újabb távlatokat nyitott meg előttem, olyanokat, amelyekről én magam se hittem, hogy elérhetőek. Ez annyiszor megtörtént velem, hogy eldöntöttem: megváltoztatom és újraírom a viselkedés-programozás szabályait."
					sm-width="auto"
				>
					<Override slot="text">
						Chase Hughes
					</Override>
					<Override slot="text1">
						Profilozó
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/h-chase.webp?v=2025-02-01T14:09:29.274Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
				<Components.TeamMember
					align-items="center"
					align-content="center"
					width="220px"
					showExtra="Show"
					textContent="Több mint 30 éve foglalkozom nemzetközi szinten kommunikációval és az etikus befolyásolás művészetével. Steve Forbes, a Forbes Media főszerkesztője a kommunikációs készségek &quot;Nagy Mesterének&quot; nevezett. Számos konferencián, szemináriumon és rendezvényen szoktam előadni az Egyesült Államokban és nemzetközi szinten is. Emellett havi rendszerességgel több mesterkurzust, valamint képzést tartok, melynek segítségével eredményesebb lehetsz az élet bármely területén."
					sm-width="auto"
				>
					<Override slot="text">
						Dr. Dave Frees
					</Override>
					<Override slot="text1">
						Befolyásolás szakértő
					</Override>
					<Override
						slot="ClonedChildren"
						min-height="100px"
						min-width="100px"
						position="relative"
						z-index="100"
						opacity="1"
						display="grid"
						justify-items="center"
						align-items="center"
						width="100%"
					/>
					<Override
						slot="SubMotionBox"
						display="grid"
						align-items="center"
						border-width="15px"
						border-style="solid"
						border-color="#EFF3FB"
						border-radius="15px"
						justify-items="center"
						background="#EFF3FB"
						box-shadow="--xxl"
					/>
					<Override
						slot="MainMotionBox"
						display="grid"
						justify-items="center"
						width="220px"
						sm-width="160px"
						sm-height="240px"
						sm-align-content="start"
					/>
					<Override slot="box" display="flex" justify-content="center" align-items="center" />
					<Override
						slot="CloseIcon"
						category="fa"
						icon={FaRegWindowClose}
						color="--brandSecondary"
						opacity="0.5"
					/>
					<Override
						slot="CloseIconWrapper"
						width="100%"
						position="sticky"
						display="flex"
						justify-content="flex-end"
						align-items="flex-start"
					/>
					<Override
						slot="PropText"
						font="--base"
						color="--brandPrimary"
						padding="10px 10px 10px 10px"
						background="#ffffff"
						border-radius="15px"
					/>
					<Override slot="image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/f-dave.webp?v=2025-02-01T14:09:29.271Z" transform="scale(1.3)" />
					<Override
						slot="More icon"
						justify-self="end"
						align-self="start"
						category="io"
						icon={IoMdMore}
						hover-border-radius="50%"
						transition="all 0.8s ease 0s"
						border-radius="10%"
						opacity="0.6"
						color="--brandSecondary"
						border-width="2px"
						border-style="dotted"
						border-color="transparent"
						hover-border-color="rgba(72, 124, 207, 0.3)"
						position="absolute"
					/>
				</Components.TeamMember>
			</Components.TeamWrapper>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			id="excerpts"
			overflow-x="hidden"
			overflow-y="hidden"
			sm-overflow-y="hidden"
			sm-overflow-x="hidden"
		>
			<Box
				padding="16px 16px 16px 16px"
				display="flex"
				flex-wrap="wrap"
				margin="-16px -16px -16px -16px"
				lg-display="grid"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-padding="0 0 0 0"
			>
				<Box padding="16px 16px 16px 16px" width="45%" lg-width="100%" sm-padding="16px 16px 16px 0">
					<Box
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
						background="url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/bence_rolunk_compressedV2.png?v=2023-12-14T13:51:05.159Z) center/cover"
					/>
				</Box>
				<Box
					width="45%"
					display="flex"
					padding="16px 16px 0px 16px"
					lg-width="100%"
					lg-margin="40px 0px 0px -50px"
					sm-margin="-80px 0px 0px 0"
					sm-padding="16px 0 0px 16px"
				>
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="darken"
						background="--color-brandCta"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-width="100%"
						position="relative"
						lg-margin="0 -40px 0 0"
						lg-padding="98px 20px 0 64px"
						sm-margin="0 0 0 0"
					>
						<Text
							as="h4"
							margin="0"
							font="--base"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Alapító
						</Text>
						<Text
							as="h1"
							margin="0 0 16px 0"
							font="--headline2"
							lg-font="--headline2"
							color="--brandLight"
						>
							Bence
						</Text>
						<Text
							as="p"
							margin="16px 0 90px 0"
							font="--lead"
							max-width="450px"
							color="--brandLight"
						>
							Az évek során rengeteget tanultam két kivételes mentortól, akik egykor a különleges haderőknél szolgáltak. Tapasztalatuk révén egyedülálló szemléletmódot nyújtottak a világ, valamint az emberi viselkedés és interakciók megértéséhez. Ezt a tudást féltve őriztem míg egy súlyos autóbaleset után rádöbbentem, hogy ezeket az ismereteket meg kell osztanom másokkal is. Ezeket szeretném most átadni számodra, illetve megmutatni, hogy Te mire vagy képes!
						</Text>
						<Link
							href="#bence"
							padding="12px 24px 12px 24px"
							color="--brandLight"
							background="--color-brandSecondary"
							text-decoration-line="initial"
							font="--lead"
							margin="28px 0 0px 0px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							sm-margin="30px 0px 16px 0px"
							sm-text-align="center"
							sm-width="60%"
							hover-transition="background-color 0.2s ease-in-out 0s"
							text-align="center"
							width="fit-content"
							align-self="flex-end"
							max-width="185px"
							position="absolute"
							right="15px"
							bottom="15px"
							sm-right="auto"
							display="none"
						>
							Bővebben &gt;&gt;
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			box-sizing="border-box"
			padding="100px 0px 100px 0px"
			quarkly-title="Our Services"
			xl-padding="100px 0px 100px 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			id="services"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				align-items="center"
			/>
			<Text
				letter-spacing="1px"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				margin="0px 0px 10px 0px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				text-transform="uppercase"
				lg-text-align="center"
				font="--base"
			>
				Mivel foglalkozunk
			</Text>
			<Text
				lg-margin="0px 0px 48px 0px"
				md-font="--headline3"
				sm-margin="0px 0px 36px 0px"
				font="--headline2"
				margin="0px 0px 72px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
			>
				Szolgáltatásaink
			</Text>
			<Box
				grid-template-columns="repeat(3, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				width="100%"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="flex-start">
					<Icon
						category="fa"
						icon={FaGlobe}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 10px 0px" display="inline-block">
						Oktatás
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
					>
						Minőségellenőrzésünk egyetlen kérdésből áll: működik ez akkor, amikor az életem a tét? Az akadémiai alapú és az eredmény alapú megközelítés között a gyakorlat a különbség. Éppen ezért amikor az eredmények számítanak, a képzés a legfontosabb.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="flex-start">
					<Icon
						category="fa"
						icon={FaRegBuilding}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Tanácsadás
					</Text>
					<Text
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
					>
						Az üzleti életben az információ önmagában semmit sem jelent. A piaci lehetőségek felmérése, és ennek fényében a tudás precíz alkalmazása (makro és mikro szinten) jelenti a különbséget a kudarc és siker között. Vállalatra szabott ajánlatért,{" "}
						<Link
							href="#contact"
							color="--brandSecondary"
							text-decoration-line="initial"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								lépj kapcsolatba
							</Strong>
						</Link>
						{" "}velünk.
					</Text>
				</Box>
				<Box justify-content="flex-start" display="flex" flex-direction="column" align-items="center">
					<Icon
						font="36px sans-serif"
						color="--primary"
						category="md"
						icon={MdBook}
						size="82px"
						margin="0px 0px 19px 0px"
					/>
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Könyvkiadás
					</Text>
					<Text
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
					>
						Célunk, hogy a közreműködésünkkel kiadott könyvek mélyreható és pozitív változásokat hozzanak olvasóink életébe. Az általunk ajánlott művek hiánypótló alkotások a kommunikáció, befolyásolás és viselkedés-pszichológia területén.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			background="#EDF2F6"
			quarkly-title="Media"
			id="media"
			sm-padding="60px 0 60px 0"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Médiamegjelenések
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				>
					Ahogyan a médiában láthattad...
				</Text>
			</Box>
			<Box grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr" display="grid">
				<Components.CardItem>
					<Override slot="text">
						Milyen ember Vlagyimir Putyin? A szakértő válaszol! - 1. rész
					</Override>
					<Override
						slot="box2"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15:36:10.609Z) 0% 0%/contain no-repeat"
						min-height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
					/>
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Élő adás:
						</Strong>
						{" "}Rádió1 Balázsék 🎥
					</Override>
					<Override slot="link" href="https://www.facebook.com/radio1hungary/videos/442251047587098" />
					<Override
						slot="box1"
						background="#47b3d0 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15:41:09.480Z) 0% 0% /contain no-repeat scroll padding-box"
						height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
					/>
					<Override slot="icon" category="fi" icon={FiRadio} />
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. március 16.
						</Span>
					</Override>
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text">
						Milyen ember Vlagyimir Putyin? A szakértő válaszol! - 2. rész
					</Override>
					<Override
						slot="box2"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15:36:10.609Z) 0% 0%/contain no-repeat"
						min-height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
					/>
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Élő adás:
						</Strong>
						{" "}Rádió1 Balázsék 🎥
					</Override>
					<Override slot="link" href="https://www.facebook.com/radio1hungary/videos/542466280431964">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Megnézem &gt;&gt;
						</Strong>
					</Override>
					<Override
						slot="box1"
						background="#47b3d0 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15:41:09.480Z) 0% 0% /contain no-repeat scroll padding-box"
						height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
					/>
					<Override slot="icon" category="fi" icon={FiRadio} />
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. március 16.
						</Span>
					</Override>
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text">
						Balázsék vendége
						<br />
						Sziklay Bence viselkedéselemző, profilozó
					</Override>
					<Override
						slot="box2"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15:36:10.609Z) 0% 0%/contain no-repeat"
						min-height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
					/>
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Élő adás:
						</Strong>
						{" "}Rádió1 Balázsék 🎥
					</Override>
					<Override slot="link" href="https://www.facebook.com/balazsek/videos/154013971090287">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Megnézem &gt;&gt;
						</Strong>
					</Override>
					<Override
						slot="box1"
						background="#47b3d0 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15:41:09.480Z) 0% 0% /contain no-repeat scroll padding-box"
						height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
					/>
					<Override slot="icon" category="fi" icon={FiRadio} />
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2023. szeptember 13.
						</Span>
					</Override>
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text">
						Radar - Elárulta magát Puytin és Zelenszkij?
					</Override>
					<Override slot="box2" background="rgba(0, 0, 0, 0)" display="none" />
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							TV műsor:
						</Strong>
						{" "}HírTV Radar
					</Override>
					<Override slot="link" href="https://hirtv.hu/video/277543">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Megnézem &gt;&gt;
						</Strong>
					</Override>
					<Override slot="icon" category="fi" icon={FiTv} />
					<Override slot="box1" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hirtv.png?v=2023-12-01T19:01:37.253Z) 0% 0% /contain no-repeat scroll padding-box" max-width="200px" />
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2023. március 31.
						</Span>
					</Override>
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text2" align-self="flex-start">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							TV műsor:
						</Strong>
						{" "}HírTV, Index.hu
					</Override>
					<Override slot="link" href="https://index.hu/kulfold/2022/09/27/haboru-orosz-ukran-konfliktus-oroszorszag-ukrajna-vlagyimir-putyin-volodimir-zelenszkij-percrol-percre-kedd/bloffolt-vagy-sem-putyin-az-atom-bevetesevel-a-testbeszede-buktatta-le/" />
					<Override slot="text" />
					<Override slot="icon" category="fi" icon={FiTv} />
					<Override slot="box1" />
					<Override slot="box2" />
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="box1" display="none" background="rgba(0, 0, 0, 0)" />
					<Override slot="box2" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/rtl.png?v=2023-11-23T15:32:45.171Z) 50% 50% /cover no-repeat scroll padding-box" />
					<Override slot="text">
						Lábrázás, testbeszéd, hosszú tárgyalóasztal – mi rajzolódik ki Putyin viselkedéséből?
					</Override>
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. március 22..
						</Span>
					</Override>
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							TV műsor:
						</Strong>
						{" "}RTL Reggeli
					</Override>
					<Override slot="link" href="https://rtl.hu/reggeli/2022/03/22/orosz-ukran-haboru-putyin-profilozo" />
					<Override slot="icon" category="fi" icon={FiTv} />
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="link" href="https://tenyek.hu/video/lelepleztek-a-mozdulatai" />
					<Override slot="box1" display="none" background="rgba(0, 0, 0, 0)" />
					<Override slot="box2" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15:34:24.737Z) 0% 0% /contain no-repeat scroll padding-box" min-width="45px" />
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. szeptember 15.
						</Span>
					</Override>
					<Override slot="text">
						III. Károlyt Leleplezték a mozdulatai
					</Override>
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							TV műsor:
						</Strong>
						{" "}TV2 Tények plusz
					</Override>
					<Override slot="icon" category="fi" icon={FiTv} />
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text">
						Lásd empatikusabban a világot! - Interjú Sziklay Bence viselkedés-elemzővel és profilozóval
					</Override>
					<Override slot="box2" background="rgba(0, 0, 0, 0)" display="none" />
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Cikk:
						</Strong>
						{" "}Hetek magazin
					</Override>
					<Override slot="box1" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hetek_logo.webp?v=2023-12-01T20:48:49.979Z) 0% 0% /contain no-repeat scroll padding-box" />
					<Override slot="link" />
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. április 21.
						</Span>
					</Override>
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text">
						Bence számos cikket írt a Behavior magazin "Pszichológia" rovatába
					</Override>
					<Override slot="box2" background="rgba(0, 0, 0, 0)" display="none" />
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Cikk:
						</Strong>
						{" "}Behaviour magazin
					</Override>
					<Override slot="link" href="https://behaviour.hu/author/sziklaybence/" />
					<Override
						slot="box1"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/logo_beha.png?v=2023-12-01T21:00:02.908Z) 0% 0% /contain no-repeat scroll padding-box"
						width="160px"
						max-width="160px"
						min-height="30px"
					/>
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. december 16.
						</Span>
					</Override>
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text">
						Milyen ember Vlagyimir Putyin? A szakértő válaszol!
					</Override>
					<Override slot="box2" background="rgba(0, 0, 0, 0)" display="none" />
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Cikk:
						</Strong>
						{" "}Haszon magazin
					</Override>
					<Override
						slot="box1"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/haszon.png?v=2023-12-01T21:12:49.384Z) 0% 0% /contain no-repeat scroll padding-box"
						max-width="200px"
						width="160px"
						min-height="29px"
					/>
					<Override slot="link" href="https://haszon.hu/megorizni/lifestyle/putyin-viselkedese">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Megnézem &gt;&gt;
						</Strong>
					</Override>
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. április 18.
						</Span>
					</Override>
				</Components.CardItem>
				<Components.CardItem>
					<Override slot="text">
						A Hazugságvizsgáló,
						<br />
						Sziklay Bence & Farkas László,
						<br />
						14 epizód
					</Override>
					<Override
						slot="box2"
						min-height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
						background="rgba(0, 0, 0, 0)"
						display="none"
					/>
					<Override slot="text2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Podcast:
						</Strong>
						{" "}PodPad 🎥
					</Override>
					<Override slot="link" href="https://podpad.hu/a-hazugsagvizsgalo">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Megnézem &gt;&gt;
						</Strong>
					</Override>
					<Override
						slot="box1"
						background="url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/podpad.svg?v=2023-11-23T15:44:09.737Z) 0% 0% /contain no-repeat scroll padding-box"
						height="80px"
						min-width="80px"
						margin="0px 5px 0px 5px"
					/>
					<Override slot="icon" category="fi" icon={FiMic} />
					<Override slot="text1">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							text-align="right"
							right={0}
							position="static"
						>
							2022. szeptember 21 - 2022 december 28.
						</Span>
					</Override>
				</Components.CardItem>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			sm-padding="60px 0 0 0"
			quarkly-title="Content-9"
			id="bence"
			overflow-x="hidden"
			overflow-y="hidden"
			display="none"
		>
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="250px"
				min-height="250px"
				padding="160px 35px 50px 35px"
				border-color="#b8bcc0"
				margin="0px 80px 0 0"
				lg-margin="40px 0px 35px 0"
				background="linear-gradient(0deg,--color-brandPrimary 0%,rgba(0, 0, 0, 0) 20%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/bence_rolunk_compressed.jpg?v=2023-12-01T13:37:05.892Z) 50% 50% /cover repeat scroll padding-box"
				border-radius="25px"
				sm-padding="50px 25px 30px 25px"
				position="relative"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					align-items="flex-end"
					align-content="flex-end"
					position="absolute"
					bottom="35px"
				>
					<Text margin="0px 0px 0px 0px" font="--base" color="--brandLight">
						Sziklay Bence - Profilozó
					</Text>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" margin="auto 0px auto 0px">
				<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="--brandCta">
					Bemutatkozás
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="--brandPrimary">
					Sziklay Bence
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Sokoldalú Tapasztalatok és Mentorok
				</Text>
				<Text margin="0px 0px 25px 0px" font="--base" color="--brandPrimary">
					James Bond ihlette karrierje során két magasan képzett különleges katona (Szabó György és Chase Hughes) vette a szárnyai alá, akiktől olyan pszichológiai és kommunikációs készségeket tanult, amelyekről nagyon kevés embernek van tudomása a világon.
				</Text>
				<Text margin="0px 0px 35px 0px" font="--base" color="--brandPrimary">
					Folyamatosan törekszik szakmai fejlődésre, különösen a neurobiológia, pszichológia, kommunikáció és szervezetfejlesztés területein. Ez az elkötelezettség vezette őt olyan elismert szakértőkkel való tanulásra és együttműködésre, mint Chase Hughes, Simon Sinek, Dr. Jordan Peterson, Prof. Dr. Andrew Huberman és Dr. Popper Péter és Dr. Bagdy Emőke. Ezek az interakciók és tanulmányok jelentős mértékben hozzájárultak Bence szakmai tudásának és készségeinek fejlődéséhez.
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			sm-padding="0 0 0 0"
			quarkly-title="Content-9"
			overflow-x="hidden"
			overflow-y="hidden"
			display="none"
		>
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="250px"
				min-height="250px"
				padding="160px 35px 50px 35px"
				border-color="#b8bcc0"
				margin="0px 80px 0 0"
				lg-margin="40px 0px 35px 0"
				background="linear-gradient(0deg,--color-brandPrimary 0%,rgba(0, 0, 0, 0) 20%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/Bagdy_es_Bence.jpg?v=2023-12-01T15:31:52.753Z) 50% 50% /cover repeat scroll padding-box"
				border-radius="25px"
				sm-padding="50px 25px 30px 25px"
				position="relative"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					align-items="flex-end"
					align-content="flex-end"
					position="absolute"
					bottom="35px"
				>
					<Text margin="0px 0px 0px 0px" font="--base" color="--brandLight">
						Dr. Bagdy Emőke és Bence
					</Text>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" margin="auto 0px auto 0px">
				<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="--brandCta">
					Tanulmányok
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Folyamatos Tanulás és Képzések
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
					color="--brandPrimary"
				>
					<Text margin="0px 0px 0px 0px">
						A Debreceni Közgazdaságtudományi Egyetemen diplomázott.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						Chase mentoráltjaként az egyedüli személy Magyarországon, aki elvégezte a "Mesterkurzus"  "Profilozó", "Kihallgatástechnika", "Viselkedési Vezetés",  és "Toxikus emberek kiszűrése"Chase Hughes tréningeket.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Paul Ekman "Facial Action Coding System" és Scott Rouse & Greg Hartley "True Crime & Body Language Tactics" képzéseit is elvégezte.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Elsajátította a Silva módszertan magyarországi képzéseit, Dr. Domján László vezetésével.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Számos egyéb vezetői képzésen vett részt.
					</Text>
				</List>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			quarkly-title="Content-9"
			overflow-x="hidden"
			overflow-y="hidden"
			sm-padding="0 0 0 0"
			display="none"
		>
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="250px"
				min-height="250px"
				padding="160px 35px 50px 35px"
				border-color="#b8bcc0"
				margin="0px 80px 0 0"
				lg-margin="40px 0px 35px 0"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/baleset.jpg?v=2023-12-01T15:39:04.405Z) 50% 50% /cover repeat scroll padding-box"
				border-radius="25px"
				sm-padding="50px 25px 30px 25px"
				position="relative"
			/>
			<Box min-width="100px" min-height="100px" margin="auto 0px auto 0px">
				<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="--brandCta">
					Tapasztalatok
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Főbb Nemzetközi Vezetői Szerepek
				</Text>
				<List
					margin="0px 0px 35px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
					color="--brandPrimary"
				>
					<Text margin="0px 0px 0px 0px">
						A HR Medico AG kelet-európai régió igazgatójaként új nemzetközi munkaerő közvetítő folyamatot dolgozott ki, amelyet ma is precedensként használnak Európa-szerte.
					</Text>
					<Text margin="0px 0px 0px 0px">
						A London Consulting Group közép-amerikai kereskedelmi igazgatójaként töltötte be globális tanácsadói szerepkörét.
					</Text>
				</List>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Médiamegjelenések és Elismertség
				</Text>
				<Text margin="0px 0px 35px 0px" font="--base" color="--brandPrimary">
					Behavior Magazin, Haszon Magazin, HírTV, RTL Klub, TV2
Index, Magyar Nemzet, Bors, Metro stb.
és még sok más...
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Életre Szóló Megpróbáltatások és Tanulságok
				</Text>
				<List
					margin="0px 0px 35px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
					color="--brandPrimary"
				>
					<Text margin="0px 0px 0px 0px">
						Közép Amerikában súlyos autóbaleset érte, melyben szilánkosra törte a csípőjét, valamint három helyen eltörte a gerincét. Visszatért a klinikai halál állapotából, amióta egyetlen cél vezérli: felemelni másokat és pozitív változásokat idézni elő bennük.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Az elvileg visszafordíthatatlan sérülések ellenére kitartással és vizualizációval újra megtanult járni.{" "}
					</Text>
				</List>
			</Box>
		</Section>
		<Hr
			min-height="10px"
			min-width="100%"
			margin="0px 0px 0px 0px"
			overflow-x="hidden"
			overflow-y="hidden"
		/>
		<Section
			padding="80px 0 80px 0"
			sm-padding="0 0 0 0"
			quarkly-title="Content-9"
			id="chase"
			overflow-x="hidden"
			overflow-y="hidden"
			display="none"
		>
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="250px"
				min-height="250px"
				padding="160px 35px 50px 35px"
				border-color="#b8bcc0"
				margin="0px 80px 0 0"
				lg-margin="40px 0px 35px 0"
				background="linear-gradient(0deg,--color-brandPrimary 0%,rgba(0, 0, 0, 0) 20%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/chase_rolunk_compressed.jpg?v=2023-12-01T15:43:39.445Z) 50% 50% /cover repeat scroll padding-box"
				border-radius="25px"
				sm-padding="50px 25px 30px 25px"
				position="relative"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					align-items="flex-end"
					align-content="flex-end"
					position="absolute"
					bottom="35px"
					top="401px"
					height="24px"
					sm-top="271px"
				>
					<Text margin="0px 0px 0px 0px" font="--base" color="--brandLight">
						Chase Hughes - Viselkedés-szakértő
					</Text>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" margin="auto 0px auto 0px">
				<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="--brandCta">
					Bemutatkozás
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="--brandPrimary">
					Chase Hughes
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					A Világ Vezető Profilozója
				</Text>
				<Text margin="0px 0px 25px 0px" font="--base" color="--brandPrimary">
					Az első számú katonai és hírszerzési viselkedés-szakértő több mint 20 éves tapasztalatával megalkotta a legfejlettebb viselkedés-ismereti képzéseket és taktikákat, melyek már magánszemélyeknek is hozzáférhetők az egész világon.
				</Text>
				<Text margin="0px 0px 25px 0px" font="--base" color="--brandPrimary">
					Chase világ vezető viselkedésprofilozási és befolyásolástechnikai szakértője.
				</Text>
				<Text margin="0px 0px 25px 0px" font="--base" color="--brandPrimary">
					Kifejlesztett egyedülálló viselkedés-ismereti képzéseket és taktikákat, melyek globálisan elérhetők.
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			sm-padding="0 0 0 0"
			quarkly-title="Content-9"
			id="bence"
			overflow-x="hidden"
			overflow-y="hidden"
			display="none"
		>
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="250px"
				min-height="250px"
				padding="160px 35px 50px 35px"
				border-color="#b8bcc0"
				margin="0px 80px 0 0"
				lg-margin="40px 0px 35px 0"
				background="linear-gradient(0deg,--color-brandPrimary 0%,rgba(0, 0, 0, 0) 20%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/bence_es_Chase.jpg?v=2023-12-01T15:51:58.403Z) 50% 50% /cover repeat scroll padding-box"
				border-radius="25px"
				sm-padding="50px 25px 30px 25px"
				position="relative"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					align-items="flex-end"
					align-content="flex-end"
					position="absolute"
					bottom="35px"
					top="401px"
					height="24px"
					sm-top="271px"
				>
					<Text margin="0px 0px 0px 0px" font="--base" color="--brandLight">
						Chase és Bence
					</Text>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" margin="auto 0px auto 0px">
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Szakmai Elismertség
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
					color="--brandPrimary"
				>
					<Text font="--base" color="--brandPrimary" margin="0 0px 0 0px">
						Bestseller író, aki a haladó szintű meggyőzés, befolyásolás és viselkedés-profilozás témájában.
					</Text>
					<Text font="--base" color="--brandPrimary" margin="0 0px 0 0px">
						Az amerikai elit különleges erők, kormányzati ügynökségek, hírszerzés és speciális rendőri alakulatok oktatója.
					</Text>
					<Text font="--base" color="--brandPrimary" margin="0 0px 0 0px">
						Kifejlesztett egyedülálló viselkedés-ismereti képzéseket és taktikákat, melyek globálisan elérhetők.
					</Text>
					<Text margin="0px 0px 0px 0px">
						A PEACE 4A kurzus, amely életmentő tudást nyújt a bűnüldözésben dolgozóknak.
					</Text>
					<Text margin="0px 0px 0px 0px">
						A Human Tradecraft kurzus, speciálisan a hírszerzési szakemberek számára.
					</Text>
				</List>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			sm-padding="0 0 80px 0"
			quarkly-title="Content-9"
			id="bence"
			overflow-x="hidden"
			overflow-y="hidden"
			display="none"
		>
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="250px"
				min-height="250px"
				padding="160px 35px 50px 35px"
				border-color="#b8bcc0"
				margin="0px 80px 0 0"
				lg-margin="40px 0px 35px 0"
				background="linear-gradient(0deg,--color-brandPrimary 0%,rgba(0, 0, 0, 0) 20%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/chasearmed.jpg?v=2023-12-01T15:58:35.680Z) 50% 50% /cover repeat scroll padding-box"
				border-radius="25px"
				sm-padding="50px 25px 30px 25px"
				position="relative"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					align-items="flex-end"
					align-content="flex-end"
					position="absolute"
					bottom="35px"
					top="401px"
					height="24px"
					sm-top="271px"
				>
					<Text margin="0px 0px 0px 0px" font="--base" color="--brandLight">
						Chase szolgálatban
					</Text>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" margin="auto 0px auto 0px">
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Katonai és Akadémiai Háttér
				</Text>
				<List
					margin="0px 0px 35px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
					color="--brandPrimary"
				>
					<Text margin="0px 0px 0px 0px">
						20 év szolgálat az Amerikai Egyesült Államok Haditengerészeténél.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Képzett a U.S. Naval War Főiskolán és tanult pszichológiát és viselkedéstudományokat, valamint a Harvardon Neurobiológiát.
					</Text>
				</List>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Innovatív Megoldások és Díjak
				</Text>
				<List
					margin="0px 0px 35px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
					color="--brandPrimary"
				>
					<Text margin="0px 0px 0px 0px">
						A világelső viselkedéselemző eszköz és a T.F.C.A. ciklus kifejlesztője.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Az Erőszak Előrejelző Index megalkotója, amely életeket menthet.
					</Text>
				</List>
				<Text margin="0px 0px 30px 0px" font="--headline3" color="--brandPrimary">
					Médiamegjelenések és Elismertség
				</Text>
				<List
					margin="0px 0px 35px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
					color="--brandPrimary"
				>
					<Text margin="0px 0px 0px 0px">
						A The Ellipsis Manual, 6MX Profiling című bestseller könyvek szerzője.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Dr. Phil által világelső magatartás és viselkedés szakértőjként említve.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Az Entrepreneur Magazinban megjelent, Csoda Tagként.
					</Text>
					<Text margin="0px 0px 0px 0px">
						Top30guru: A világ elsőszámú testbeszéd szakértőjének szavazták már három egymást követő éve
					</Text>
					<Text margin="0px 0px 0px 0px">
						és még sok más....
					</Text>
				</List>
				<Text margin="0px 0px 0px 0px" font="--lead" color="--brandPrimary">
					Chase Hughes szakértelme és innovációi kulcsfontosságúak vállalatunk számára. Hiszünk abban, hogy az ő tudása és módszerei révén ügyfeleink a legmagasabb szintű képzéseket és ismereteket kapják, amelyek elősegítik személyes és szakmai fejlődésüket.
				</Text>
			</Box>
		</Section>
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://images.unsplash.com/photo-1547619292-240402b5ae5d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80)"
			quarkly-title="Form-1"
			id="contact"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="45%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Levelezési Címünk
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Digital Neurons Ltd.
								<br />
								<br />
								71-75 Shelton Street,
								<br />
								Covent Garden,
								<br />
								London,
								<br />
								England,
								<br />
								WC2H 9JQ
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email címünk
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:info@sziklaybence.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									info@sziklaybence.com
								</Link>
							</Text>
						</Box>
						<Box
							padding="0 0 0 64px"
							margin="64px 0 0 0"
							max-width="360px"
							position="relative"
							display="none"
						>
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Telefonszámunk
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+44
								<br />
								+44
							</Text>
						</Box>
						<Box
							sm-padding="0"
							margin="48px 0"
							max-width="360px"
							position="relative"
							display="flex"
							padding="0 0 0 64px"
						>
							<LinkBox margin="0px 16px 0px 0px" href="https://www.facebook.com/sziklaybenceofficial/" target="_blank">
								<Icon
									width="48px"
									height="48px"
									size="24px"
									background="--color-primary"
									border-radius="50%"
									category="fa"
									icon={FaFacebookF}
									color="--light"
									margin="0px 0px 0px 0px"
								/>
							</LinkBox>
							<LinkBox margin="0px 16px 0px 0px" href="https://www.instagram.com/sziklaybenceofficial/" target="_blank">
								<Icon
									width="48px"
									height="48px"
									size="24px"
									background="--color-primary"
									border-radius="50%"
									category="fa"
									icon={FaInstagram}
									color="--light"
									margin="0px 0px 0px 0px"
								/>
							</LinkBox>
							<LinkBox margin="0px 16px 0px 0px" position="relative" href="https://www.tiktok.com/@sziklaybenceofficial" target="_blank">
								<Icon
									background="--color-primary"
									border-radius="50%"
									category="fa"
									icon="fa-brands fa-tiktok"
									width="48px"
									height="48px"
									size="24px"
									color="--light"
									margin="0px 0px 0px 0px"
								/>
								<Image
									src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/tiktok.svg?v=2023-12-01T22:54:55.299Z"
									display="block"
									width="26px"
									position="absolute"
									height="26px"
									color="#ffffff"
									filter="invert(100%)"
								/>
							</LinkBox>
							<LinkBox margin="0px 16px 0px 0px" href="https://www.linkedin.com/in/ben-sz/" target="_blank">
								<Icon
									category="fa"
									icon={FaLinkedinIn}
									width="48px"
									height="48px"
									size="24px"
									background="--color-primary"
									border-radius="50%"
									color="--light"
									margin="0px 0px 0px 0px"
								/>
							</LinkBox>
							<LinkBox margin="0px 16px 0px 0px" href="https://www.youtube.com/@sziklaybenceofficial" target="_blank">
								<Icon
									category="fa"
									icon={FaYoutube}
									width="48px"
									height="48px"
									size="24px"
									background="--color-primary"
									border-radius="50%"
									color="--light"
									margin="0px 0px 0px 0px"
								/>
							</LinkBox>
						</Box>
					</Box>
				</Box>
				<Box width="45%" padding="8px 8px 8px 8px" md-width="100%">
					<Box>
						<Box
							padding="56px 48px"
							margin="0 0 0 auto"
							md-max-width="100%"
							background="--color-brandSecondary"
							max-width="360px"
							border-radius="15px"
						>
							<Text as="h3" font="--headline3" margin="0 0 20px 0">
								Írj nekünk
							</Text>
							<Formspree endpoint="mjvqqzvq" completeText="Sikeresen elküldve." errorMessage="Hiba történt.">
								<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text margin="0 0 4px 0" font="--base">
												Név
											</Text>
											<Input max-width="400px" width="100%" name="name" background="--color-brandLight" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Email
											</Text>
											<Input
												max-width="400px"
												width="100%"
												type="email"
												name="email"
												background="--color-brandLight"
											/>
										</Box>
									</Box>
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Üzenet
											</Text>
											<Input
												width="100%"
												name="message"
												as="textarea"
												rows="4"
												background="--color-brandLight"
											/>
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column" align-items="flex-start">
											<Button background="--color-brandPrimary" opacity="0.8" hover-background="--color-orange">
												Küldés
											</Button>
										</Box>
									</Box>
								</Box>
							</Formspree>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.FooterGlobal>
			<Override slot="CreatorSignature/image" src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/logo.svg?v=2025-02-11T15:31:40.265Z" />
		</Components.FooterGlobal>
		<Components.QuarklycommunityKitBackToTop showAlways={false} overflow-x="hidden" overflow-y="hidden">
			<Override slot="Icon" color="--brandCta" />
		</Components.QuarklycommunityKitBackToTop>
	</Theme>;
});